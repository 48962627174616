import { Controller } from "@hotwired/stimulus"
import tinymce from 'tinymce'
import getCookieByName from '~/javascripts/read_cookie'

export default class extends Controller {
  static targets = ["tinymce"]

  tinymceTargetConnected(selectInput) {
    let options = {selector: '.tinymce'}

    const theme = getCookieByName('theme')

    if (theme == 'dark') {
      options['skin'] = "oxide-dark"
      options['content_css'] = "dark"
    }

    tinymce.init(options)
  }

  tinymceTargetDisconnected(selectInput) {
    tinymce.remove('.tinymce')
  }
}
