<script>
  import VendorList from '../../components/vendors/VendorList.vue'

  export default {
    name: 'ActiveVendors',
    mount_element: 'active-vendors-app',
    components: { VendorList },
    data() {
      return {
        api_endpoint: '/api/vendors.json'
      }
    },
  }
</script>

<template>
  <vendor-list :api_endpoint="api_endpoint"></vendor-list>
</template>
