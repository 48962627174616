<template lang="pug">
li.list-group-item.px-0.border-0
  .row.align-items-center
    .col-auto
      i.fas.fa-car
    .col
      p.text-xs.font-weight-bold.mb-0 New Inventory Added:
      h6.text-sm.mb-0
        a(:href='inventoryUrl') {{ inventoryName }}
    .col.text-center
      p.text-xs.font-weight-bold.mb-0 Created by:
      h6.text-sm.mb-0 {{ creatorName }}
    .col.text-center
      p.text-xs.font-weight-bold.mb-0 Added at:
      h6.text-sm.mb-0 {{ formattedCreatedAt }}
  hr.horizontal.dark.mt-3.mb-1
</template>

<script>
  import { get } from 'lodash'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      inventoryName() {
        return get(this, 'item.inventory.simple_name')
      },
      inventoryUrl() {
        const inventory_id = get(this, 'item.inventory.friendly_id')
        if (!inventory_id) { return null }

        return `/inventory/${inventory_id}/edit`
      },
      creatorName() {
        return get(this, 'item.inventory.user.full_name')
      },
      createdAt() {
        return get(this, 'item.created_at')
      },
      formattedCreatedAt() {
        const date = new Date(this.createdAt)
        const options = { weekday: 'long', month: 'short', day: 'numeric', year: 'numeric' }
        return date.toLocaleDateString('en-US', options)
      }
    },
    setup(props, { emit }) {
    }
  }
</script>

<style lang="sass" scoped>
</style>
