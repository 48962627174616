document.addEventListener('turbo:load', () => {
  const sortDropdown = document.getElementById('sort-lead-dropdown');
  const queryButton = document.getElementById('query-lead-button');

  const selectElement = document.getElementById('search-by-letter');

  if (selectElement) {
    selectElement.addEventListener('change', () => {
      const selectedHref = selectElement.value;
      const urlObject = new URL(selectedHref, window.location.href);

      const redirectUrl = `/api/leads/letter_sort${urlObject.search}&sort=name`;

      window.location.href = redirectUrl;
    });
  }

  if(queryButton){

    queryButton.addEventListener('click', function (event) {
      event.preventDefault();

      const selectedSortValue = sortDropdown.value;

      const redirectUrl = `/api/leads/filter_sort?sort=${selectedSortValue}`;

      window.location.href = redirectUrl;
    });
  }
  var checkmark = document.getElementById('checkmark-lead-status');
  checkmark.style.display = 'none';
  $("#contact_status").on("change", function(event) {
    const selectedValue = $(this).val();

    setTimeout(function() {
      checkmark.style.display = 'none';
    }, 2000);
    if (selectedValue) {
        checkmark.style.display = 'inline-block';
    } else {
        checkmark.style.display = 'none';
    }
    var contactId = $("#contact_id").val();
    var statusValue = $(this).val();
    if (!statusValue && statusValue !== 0) {
        statusValue = "";
    }
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    $.ajax({
      url: "/leads/" + contactId + ".json",
      type: "PUT",
      data: {
        authenticity_token: csrf,
        id: contactId,
        contact: {
            status: statusValue
        }
      }
    });
  });

  $(".ofac_check.contacts").click(function(e) {
    e.preventDefault();
    
    var last_name = $("#contact_last_name").val();
    var first_name = $("#contact_first_name").val();
    var company_name = $("#contact_company_name").val();
    var id = $("#contact_id").val();
    var spinner = $(this).closest(".card-body").find(".spinner-ofac");
    spinner.show();

    $.ajax({
        url: "/leads/ofac_check.json",
        type: "POST",
        data: {
            authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
            contact: {
                id: id,
                last_name: last_name,
                first_name: first_name,
                company_name: company_name
            }
        },
        success: function(data, status, xhr) {
          setTimeout(function(){
            spinner.hide();
          }, 500);
          $("#ofac_status").text(data.status);
          $("#ofac_status").effect('highlight');
          $("#ofac_last_checked").text(data.last_ran);
          $("#contact_ofac_passed").val(data.ofac_passed);
          $("#contact_ofac_last_run").val(data.ofac_last_run_date);
        }
    });
  });
})
