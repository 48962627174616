import { Controller } from "@hotwired/stimulus"
import { DataTable } from "simple-datatables"

export default class extends Controller {
  static targets = ["table"]

  tableTargetConnected(element) {
    new DataTable(element, {
      searchable: true,
      perPageSelect: false,
      labels: {
        placeholder: "Search...",
        perPage: "{select} entries per page",
        noRows: "No entries to show",
        info: "Showing {start} to {end} of {rows} entries"
      }
    })
  }

}
