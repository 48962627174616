<script>
import InventoryList from "../../components/inventory/InventoryList.vue";

export default {
  name: "ActiveInventory",
  mount_element: "active-inventory-app",
  components: { InventoryList },
  data() {
    return {
      api_endpoint: "/api/inventory.json",
    };
  },
};
</script>

<template>
  <inventory-list :api_endpoint="api_endpoint"></inventory-list>
</template>
