document.addEventListener('turbo:load', () => {
  $("#add_feature_button").unbind("click");
  $('a.accordion-toggle').unbind("click");
  $(document).ready(function() {
    $('.applyBtn').on('click', function() {
      var dateRange = $('#dateRangeInput').val();
      var selectedNavValued =  $('.nav-link.selected').attr('data-value') || 'd';
      var dateArray = dateRange.split(' - ');
      var startDate = dateArray[0];
      var endDate = dateArray[1];
      var url = '/overview?start_date=' + startDate + '&end_date=' + endDate + '&nav_stat=' + selectedNavValued;
      Turbo.visit(url, { action: 'replace', frame: "overview-frame" });
    });
    var startDate, endDate;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const startDateParam = urlSearchParams.get("start_date");
    const endDateParam = urlSearchParams.get("end_date");
    if (startDateParam && endDateParam){
      $('#dateRangeInput').val(startDateParam + ' - ' + endDateParam);
      var parentElement = document.getElementsByClassName('choices__list--single')[1];
      var elementToHide = parentElement.getElementsByClassName('choices__item--selectable')[0];
      if (elementToHide) {
        elementToHide.style.display = 'none';
      }
      endDate = new Date(endDateParam)
      startDate = new Date(startDateParam)
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    if ($('#dailySelect').val() === 'week') {
      if (!startDateParam){
        endDate = new Date();
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);
        startDate = formatDate(startDate);
        endDate = formatDate(endDate);
        $('#dateRangeInput').val(startDate + ' - ' + endDate);
      }
    }
    else if($('#dailySelect').val() === 'mon'){
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 29);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if ($('#dailySelect').val() === '21d'){
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(startDate.getDate() - 21);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if($('#dailySelect').val() === 'mtd'){
      endDate = new Date();
      startDate = new Date();
      startDate.setDate(1);
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if($('#dailySelect').val() === 'qtd'){
      endDate = new Date();
      startDate = getStartOfQuarter(new Date());
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    else if($('#dailySelect').val() === 'ytd'){
      endDate = new Date();
      var startDate = getStartOfYear(new Date());
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
      $('#dateRangeInput').val(startDate + ' - ' + endDate);
    }
    var selectedNavValued =  $('.nav-link.selected').attr('data-value') || 'd';
    $('#dailySelect').on('change', function() {
      selectedSelectValue = $(this).val();
      var url = '/overview?graph_stat=' + selectedSelectValue + '&nav_stat=' + selectedNavValued;
      Turbo.visit(url, { action: 'replace', frame: "overview-frame" });
    });
    $('.nav-link.active').data('value', selectedSelectValue);

    var selectedSelectValue = $('#dailySelect').val();
    var selectedNavValue = "d";
    $('.nav-link[data-value="' + selectedNavValue + '"]').addClass('active'); // Add 'active' class to the default nav link

    $('.navLink').on('click', function(e) {
      e.preventDefault();
      $('.nav-link').removeClass('active'); // Remove 'active' class from all nav links
      $(this).addClass('active'); // Add 'active' class to the clicked nav link
      selectedNavValue = $(this).data('value');
      const urlSearchParams = new URLSearchParams(window.location.search);
      const startDateParam = urlSearchParams.get("start_date");
      const endDateParam = urlSearchParams.get("end_date");
      if (startDateParam && endDateParam){
        $('#dateRangeInput').val(startDateParam + ' - ' + endDateParam);
        var url = '/overview?start_date=' + startDateParam + '&end_date=' + endDateParam + '&nav_stat=' + selectedNavValue;
      }
      else {
        var url = '/overview?graph_stat=' + selectedSelectValue + '&nav_stat=' + selectedNavValue;
      }
      Turbo.visit(url, { action: 'replace', frame: "overview-frame" });
    });

    // Update select menu when nav link is clicked
    $('#dailySelect').val(selectedNavValue);

    $('#collapse-feature').css('display', 'block');

    $('.clear_features').on('click', function (event) {
      var section, name, $el;

      event.preventDefault();

      section = $(this).data('feature-section');
      name = 'inventory[features][' + section + '][]';
      $el = $('#' + section + '_features');
      $el.empty();
      if ($('input[name="' + name + '"]').length === 0) $el.after('<input type="hidden" name="' + name + '">');
    });

    const form = document.querySelector('form');
    const yesCheckbox = document.getElementById('header_row_yes');
    const noCheckbox = document.getElementById('header_row_no');

    form.addEventListener('submit', function(event) {
      if (!yesCheckbox.checked && !noCheckbox.checked) {
        alert('Please select at least one option (Yes or No).');
        event.preventDefault();
      }
    });
    var links = document.querySelectorAll('.add-vendor');
    var forms = document.querySelectorAll('.inner-form-vendor');
    forms.forEach(function(form) {
        form.style.display = 'none'; // Hide all forms initially
    });

    links.forEach(function(link, index) {
        link.addEventListener('click', function(e) {
            e.preventDefault(); // Prevent default link behavior
            var forms = document.querySelectorAll('.inner-form-vendor');
            var formToShow = forms[index];
            var isFormDisplayed = formToShow.style.display === 'block';

            forms.forEach(function(form, i) {
                form.style.display = (i === index && !isFormDisplayed) ? 'block' : 'none';
            });

            // Update link text based on form display status
            if (isFormDisplayed) {
                link.textContent = 'Add new vendor';
                link.style.color = '#5e72e4';
            } else {
                link.textContent = 'Remove New Vendor';
                link.style.color = 'red';
            }
        });
    });

    const checkboxes = document.querySelectorAll('.form-check-input');

    checkboxes.forEach(function(checkbox) {
      checkbox.addEventListener('change', function() {
        if (this.checked) {
          // Uncheck the other checkbox
          if (this.id === 'header_row_yes') {
            noCheckbox.checked = false;
          } else if (this.id === 'header_row_no') {
            yesCheckbox.checked = false;
          }
        }
      });
    });
    $('#remind-at').hide();
    $('#reminder-checkbox').change(function() {
      if ($(this).is(':checked')) {
        $('#remind-at').show();
      } else {
        $('#remind-at').hide();
      }
    });
  });
  $('a.accordion-toggle').on('click', function(e) {
    e.preventDefault();
    $('#collapse-feature').toggle();
  });

  $('#custom_link_field').hide();

  $('#add_custom_link').click(function(event){
    event.preventDefault();
    $('#custom_link_field').show();
    $('#add_custom_link').hide();
  });

  var accordionToggles = document.querySelectorAll('.accordion-toggle');

  accordionToggles.forEach(function (toggle) {
  toggle.addEventListener('click', function (event) {
    event.preventDefault();

    var accordionBodyId = this.getAttribute('href').substring(1);
    var accordionBody = document.getElementById(accordionBodyId);

    // Toggle the 'hidden' class to show/hide the accordion body
    if (accordionBody.style.display === 'none' || accordionBody.style.display === '') {
      accordionBody.style.display = 'block';
    } else {
      accordionBody.style.display = 'none';
    }
    });
  });
  $("input#add_feature").keypress(function(e){
    // 13 = enter key
    if (e.which == 13) { e.preventDefault(); add_feature_via_form() }
  });
  $("#add_feature_button").click(function(e){
    e.preventDefault();
    add_feature_via_form();
    $("#add_feature").focus();
  });
  function add_feature_via_form(){
    var feature_field = $("#add_feature")
    var value = $("#add_feature").val();
    feature_field.val("");
    add_feature_to_inventory(value, "standard")
  }
  function random_string(length) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
    if (! length) {
        length = Math.floor(Math.random() * chars.length);
    }
    var str = '';
    for (var i = 0; i < length; i++) {
        str += chars[Math.floor(Math.random() * chars.length)];
    }
    return "item_" + str;
  }
  
  function formatDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return month + '/' + day + '/' + year;
  }
  function getStartOfQuarter(date) {
    const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
    return new Date(date.getFullYear(), quarterStartMonth, 1);
  }
  function getStartOfYear(date) {
    return new Date(date.getFullYear(), 0, 1);
  }
  function add_feature_to_inventory(value, type){
    var id = random_string();
    var new_feature_input = "<input id='" + id + "' " +
      "name='inventory[features][" + type + "][]' type='hidden' " +
      "value='" + value + "'></div>";
    var new_feature_el = "<div style='margin-right: 5px' class='feature' id='" + id + "'>" +
                  value +
                  ' <a href="" class="fa fa-remove remove_feature"><i class="icon-remove"></i></a>'.
                  concat(new_feature_input);
    var add_to = type == "standard" ? "#standard_features" : "#optional_features";
    $(add_to + ".dropzone").append(new_feature_el);
  }

  $(function(){
    $("#window_sticker_id").change(function () {
      var sticker_id = $(this).find("option:selected").attr('value');
      var path = "print_window_sticker";
      if (sticker_id) window.open(path + "/" + sticker_id + ".pdf", "_blank");
    })
  });

  $(function(){
    $("#export_vehicle_html_id").change(function () {
      var template_id = $(this).find("option:selected").attr('value');
      var path = "export_item_html";
      if (template_id) window.open(path + "?template=" + template_id, "_blank");
    })
  });
  
  $(function(){
    $("#export_vehicle_img_id").change(function () {
      var template_id = $(this).find("option:selected").attr('value');
      var path = "export_item_img";
      if (template_id) window.open(path + "?template=" + template_id, "_blank");
    })
  });

  $("#third_party_exports").hide();
  $(document).on("click", "#show-exports-link", function(e) {
    e.preventDefault();

    var thirdPartyExports = $("#third_party_exports");

    if (thirdPartyExports.is(":visible")) {
      thirdPartyExports.hide();
      $("#show-exports-link").text("Show exports");
    } else {
      thirdPartyExports.show();
      $("#show-exports-link").text("Hide exports");
    }
  });
  $(document).ready(function(){
    $(".update-checkmark").hide();
    $(".update-button-partner").click(function(){
      $(".update-checkmark").toggle();
    });
  });

  $('body').on("click", ".remove_feature", function (event) {
    var $feature, $features, section;

    event.preventDefault();

    $feature = $(event.target).closest(".feature");
    $features = $feature.parent();
    section = $features.attr('id').replace('_features', '');

    $feature.remove();
    if ($features.children().length === 0) $features.html('<input type="hidden" name="inventory[features][' + section + '][]">');
  });
  $("#optional_features.dropzone").sortable({
    items: '.feature',
    connectWith: '#standard_features.dropzone',
    containment: '.features_section',
    receive: function(event, ui){
      var new_id = $(ui.item).attr("id");
      if ($(ui.sender).attr("id") == "common_features"){
        add_feature_to_inventory($(ui.item).text().trim(), "optional");
        $("#" + new_id).remove();
      } else {
        update_feature_details(new_id, 'optional');
      }
    }
  });

  $("#standard_features.dropzone").sortable({
    items: '.feature',
    connectWith: '#optional_features.dropzone',
    containment: '.features_section',
    receive: function(event, ui){
      var new_id = $(ui.item).attr("id");
      if ($(ui.sender).attr("id") == "common_features"){
        add_feature_to_inventory($(ui.item).text().trim(), "standard");
        $("#" + new_id).remove();
      } else {
        update_feature_details(new_id, 'standard');
      }
    }
  });

  function update_feature_details(el, section){
    var old_section = section == "standard" ? "optional" : "standard";
    var item = $("input#" + el + "[type=hidden]");
    var name = item.attr("name");
    var new_section = name.replace(old_section, section);
    item.attr("name", new_section);
  }

  $("#common_features").sortable({
    items: '.feature',
    connectWith: "#optional_features.dropzone, #standard_features.dropzone"
  });

  $(function () {
    function adjustBuyersGuideWarranty() {
      var warrantyCheckbox = $('#buyers_yes'); // Change to the appropriate checkbox ID

      if (warrantyCheckbox.prop('checked')) {
        $('.buyers-guide-warranty').show();
      } else {
        $('.buyers-guide-warranty').hide();
      }
    }
    adjustBuyersGuideWarranty();

    $('input[name="buyers_guide[warranty]"]').change(function() {
      $('input[name="buyers_guide[warranty]"]').not(this).prop('checked', false);
      adjustBuyersGuideWarranty();
    });
    $('input[name="buyers_guide[full_warranty]"]').change(function() {
      $('input[name="buyers_guide[full_warranty]"]').not(this).prop('checked', false);
    });
    $('input[name="buyers_guide[service_contract]"]').change(function() {
      $('input[name="buyers_guide[service_contract]"]').not(this).prop('checked', false);
    });
    $('input[name="buyers_guide[english]"]').change(function() {
      $('input[name="buyers_guide[english]"]').not(this).prop('checked', false);
    });
  });

  $(document).ready(function() {
    $("#post-to-facebook").on("click", function(event) {
      event.preventDefault();
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent($(this).data("url")), "Post to Facebook", "width=640, height=480");
      var vehicleId = $(this).attr('data-vehicle');
      $.ajax({
        url: '/inventory/' + vehicleId + '/export_log_record',
        type: 'POST',
        data: {
          location: "Facebook"
        },
      });
    });
  });

  $(function(){
    $(".buyers_guide_select").change(function () {
      var url = $(this).find("option:selected").val()
      if (url) window.open(url, "_blank");
    })
  });

  function initializePage() {
      const sortDropdown = document.getElementById('sort-dropdown');
      const filterDropdown = document.getElementById('filter-dropdown');
      const queryButton = document.getElementById('query-button');
      if(queryButton){

        queryButton.addEventListener('click', function (event) {
          event.preventDefault();

          const selectedSortValue = sortDropdown.value;
          const selectedFilterValue = filterDropdown.value;

          const redirectUrl = `/api/inventory/filter_sort?sort=${selectedSortValue}&filter=${selectedFilterValue}`;

          window.location.href = redirectUrl;
        });
      }
      const photoOverlay = document.getElementById('photo-overlay');
      if(photoOverlay){

        photoOverlay.addEventListener('click', function (event) {
          event.preventDefault();

          const redirectUrl = `/inventory/photo_overlay`;

          window.location.href = redirectUrl;
        });
      }
    }
  initializePage();

  document.addEventListener("DOMContentLoaded", function () {
    initializePage();
  });
  document.addEventListener("DOMContentLoaded", function() {
      // Add an event listener to the select element
    const importSection = document.getElementById("import_section");
    importSection.addEventListener("change", function() {
      const selectedValue = importSection.value;
      // Hide both options by default
      document.getElementById("cars_for_sale_options").style.display = "none";
      document.getElementById("autotrader_options").style.display = "none";

      // Show the corresponding option based on the selected value
      if (selectedValue === "CarsForSale") {
        document.getElementById("cars_for_sale_options").style.display = "block";
      } else if (selectedValue === "AutoTrader") {
        document.getElementById("autotrader_options").style.display = "block";
      }
    });
  });
  function toggleDropdown(dropdownToggle, dropdownMenu) {
    dropdownToggle.addEventListener("click", function (event) {
      event.preventDefault();
      event.stopPropagation();

      closeAllDropdowns();

      dropdownMenu.classList.toggle("show");
    });
  }

  function closeAllDropdowns() {
    var openDropdowns = document.querySelectorAll(".dropdown-menu.show");
    openDropdowns.forEach(function (openDropdown) {
      openDropdown.classList.remove("show");
    });
  }

  var shareDropdownToggle = document.querySelector("#shareDropdownToggle");
  var shareDropdownMenu = document.querySelector("#shareDropdownToggle + .dropdown-menu");

  var manageDropdownToggle = document.querySelector("#manageDropdownToggle");
  var manageDropdownMenu = document.querySelector("#manageDropdownToggle + .dropdown-menu");

  if (shareDropdownToggle) {
    toggleDropdown(shareDropdownToggle, shareDropdownMenu);
  }

  if (manageDropdownToggle) {
    toggleDropdown(manageDropdownToggle, manageDropdownMenu);
  }

  document.addEventListener("click", function (event) {
    closeAllDropdowns();
  });
})
