// app/javascript/controllers/link_toggler_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "externalFieldset",
    "internalFieldset",
    "externalLink",
    "internalLink",
  ];

  connect() {
    this.externalFieldsetTarget.style.display = "none";
    this.externalLinkTarget.addEventListener(
      "click",
      this.toggleExternal.bind(this),
    );
    this.internalLinkTarget.addEventListener(
      "click",
      this.toggleInternal.bind(this),
    );
  }

  toggleExternal(event) {
    event.preventDefault();
    this.externalLinkTarget.style.display = "none";
    this.internalLinkTarget.style.display = "inline";
    this.externalFieldsetTarget.style.display = "block";
    this.internalFieldsetTarget.style.display = "none";
  }

  toggleInternal(event) {
    event.preventDefault();
    this.internalLinkTarget.style.display = "none";
    this.externalLinkTarget.style.display = "inline";
    this.internalFieldsetTarget.style.display = "block";
    this.externalFieldsetTarget.style.display = "none";
  }
}
