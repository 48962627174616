"use strict";

import PerfectScrollbar from 'perfect-scrollbar'
import { Tooltip, Popover, Toast } from 'bootstrap'
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

document.addEventListener('turbo:load', () => {

  (function() {
    // var ctx2 = document.getElementById("chart-pie").getContext("2d");

    // if  (ctx2) {
    //   new Chart(ctx2, {
    //     type: "pie",
    //     data: {
    //       labels: ['Facebook', 'Direct', 'Organic', 'Referral'],
    //       datasets: [{
    //         label: "Projects",
    //         weight: 9,
    //         cutout: 0,
    //         tension: 0.9,
    //         pointRadius: 2,
    //         borderWidth: 2,
    //         backgroundColor: ['#17c1e8', '#5e72e4', '#3A416F', '#a8b8d8'],
    //         data: [1, 0, 0, 0],
    //         fill: false
    //       }],
    //     },
    //     options: {
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       plugins: {
    //         legend: {
    //           display: true,
    //         }
    //       },
    //       interaction: {
    //         intersect: false,
    //         mode: 'index',
    //       },
    //       scales: {
    //         y: {
    //           grid: {
    //             drawBorder: false,
    //             display: false,
    //             drawOnChartArea: false,
    //             drawTicks: false,
    //           },
    //           ticks: {
    //             display: false
    //           }
    //         },
    //         x: {
    //           grid: {
    //             drawBorder: false,
    //             display: false,
    //             drawOnChartArea: false,
    //             drawTicks: false,
    //           },
    //           ticks: {
    //             display: false,
    //           }
    //         },
    //       },
    //     },
    //   });
    // }

    var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      if (document.getElementsByClassName('main-content')[0]) {
        var mainpanel = document.querySelector('.main-content');
        var ps = new PerfectScrollbar(mainpanel);
      };

      if (document.getElementsByClassName('sidenav')[0]) {
        var sidebar = document.querySelector('.sidenav');
        var ps1 = new PerfectScrollbar(sidebar);
      };

      if (document.getElementsByClassName('navbar-collapse')[0]) {
        var fixedplugin = document.querySelector('.navbar:not(.navbar-expand-lg) .navbar-collapse');
        var ps2 = new PerfectScrollbar(fixedplugin);
      };

      if (document.getElementsByClassName('fixed-plugin')[0]) {
        var fixedplugin = document.querySelector('.fixed-plugin');
        var ps3 = new PerfectScrollbar(fixedplugin);
      };

      if ( document.getElementsByClassName('partners-quick-navigation-card')[0]) {
        const qnc = document.querySelector('.partners-quick-navigation-card');
        new PerfectScrollbar(qnc);
      };

    };
  })();

  // Verify navbar blur on scroll
  if (document.getElementById('navbarBlur')) {
    navbarBlurOnScroll('navbarBlur');
  }

  // initialization of Popovers
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })

  // initialization of Tooltips
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  // initialization of Toasts
  document.addEventListener("DOMContentLoaded", function() {
    var toastElList = [].slice.call(document.querySelectorAll(".toast"));

    var toastList = toastElList.map(function(toastEl) {
      return new Toast(toastEl);
    });

    var toastButtonList = [].slice.call(document.querySelectorAll(".toast-btn"));

    toastButtonList.map(function(toastButtonEl) {
      toastButtonEl.addEventListener("click", function() {
        var toastToTrigger = document.getElementById(toastButtonEl.dataset.target);

        if (toastToTrigger) {
          var toast = Toast.getInstance(toastToTrigger);
          toast.show();
        }
      });
    });
  });

  // when input is focused add focused class for style
  window.focused = function(el) {
    if (el.parentElement.classList.contains('input-group')) {
      el.parentElement.classList.add('focused');
    }
  }

  // when input is focused remove focused class for style
  window.defocused = function(el) {
    if (el.parentElement.classList.contains('input-group')) {
      el.parentElement.classList.remove('focused');
    }
  }

  // helper for adding on all elements multiple attributes
  function setAttributes(el, options) {
    Object.keys(options).forEach(function(attr) {
      el.setAttribute(attr, options[attr]);
    })
  }

  // adding on inputs attributes for calling the focused and defocused functions
  if (document.querySelectorAll('.input-group').length != 0) {
    var allInputs = document.querySelectorAll('input.form-control');
    allInputs.forEach(el => setAttributes(el, {
      "onfocus": "focused(this)",
      "onfocusout": "defocused(this)"
    }));
  }

  // Multi Level Dropdown
  function dropDown(a) {
    if (!document.querySelector('.dropdown-hover')) {
      event.stopPropagation();
      event.preventDefault();
      var multilevel = a.parentElement.parentElement.children;

      for (var i = 0; i < multilevel.length; i++) {
        if (multilevel[i].lastElementChild != a.parentElement.lastElementChild) {
          multilevel[i].lastElementChild.classList.remove('show');
        }
      }

      if (!a.nextElementSibling.classList.contains("show")) {
        a.nextElementSibling.classList.add("show");
      } else {
        a.nextElementSibling.classList.remove("show");
      }
    }
  }

  // Fixed Plugin
  if (document.querySelector('.fixed-plugin')) {
    var fixedPlugin = document.querySelector('.fixed-plugin');
    var fixedPluginButton = document.querySelector('.fixed-plugin-button');
    var fixedPluginButtonNav = document.querySelector('.fixed-plugin-button-nav');
    var fixedPluginCard = document.querySelector('.fixed-plugin .card');
    var fixedPluginCloseButton = document.querySelectorAll('.fixed-plugin-close-button');
    var navbar = document.getElementById('navbarBlur');
    var buttonNavbarFixed = document.getElementById('navbarFixed');

    if (fixedPluginButton) {
      fixedPluginButton.onclick = function() {
        if (!fixedPlugin.classList.contains('show')) {
          fixedPlugin.classList.add('show');
        } else {
          fixedPlugin.classList.remove('show');
        }
      }
    }

    if (fixedPluginButtonNav) {
      fixedPluginButtonNav.onclick = function() {
        if (!fixedPlugin.classList.contains('show')) {
          fixedPlugin.classList.add('show');
        } else {
          fixedPlugin.classList.remove('show');
        }
      }
    }

    fixedPluginCloseButton.forEach(function(el) {
      el.onclick = function() {
        fixedPlugin.classList.remove('show');
      }
    })

    document.querySelector('body').onclick = function(e) {
      if (e.target != fixedPluginButton && e.target != fixedPluginButtonNav && e.target.closest('.fixed-plugin .card') != fixedPluginCard) {
        fixedPlugin.classList.remove('show');
      }
    }

    if (navbar) {
      if (navbar.getAttribute('data-scroll') == 'true' && buttonNavbarFixed) {
        buttonNavbarFixed.setAttribute("checked", "true");
      }
    }

  }

  //Set Sidebar Color
  window.sidebarColor = function(a) {
    var parent = a.parentElement.children;
    var color = a.getAttribute("data-color");

    for (var i = 0; i < parent.length; i++) {
      parent[i].classList.remove('active');
    }

    if (!a.classList.contains('active')) {
      a.classList.add('active');
    } else {
      a.classList.remove('active');
    }

    var sidebar = document.querySelector('.sidenav');
    sidebar.setAttribute("data-color", color);

    if (document.querySelector('#sidenavCard')) {
      var sidenavCard = document.querySelector('#sidenavCard+.btn');
      let sidenavCardClasses = ['btn', 'btn-sm', 'w-100', 'mb-0', 'bg-gradient-' + color];
      sidenavCard.removeAttribute('class');
      sidenavCard.classList.add(...sidenavCardClasses);
    }
  }

  // Set Sidebar Type
  window.sidebarType = function (a) {
    var parent = a.parentElement.children;
    var color = a.getAttribute("data-class");
    var body = document.querySelector("body");
    var bodyWhite = document.querySelector("body:not(.dark-version)");
    var bodyDark = body.classList.contains('dark-version');

    var colors = [];

    for (var i = 0; i < parent.length; i++) {
      parent[i].classList.remove('active');
      colors.push(parent[i].getAttribute('data-class'));
    }

    if (!a.classList.contains('active')) {
      a.classList.add('active');
    } else {
      a.classList.remove('active');
    }

    var sidebar = document.querySelector('.sidenav');

    for (var i = 0; i < colors.length; i++) {
      sidebar.classList.remove(colors[i]);
    }

    sidebar.classList.add(color);


    // Remove text-white/text-dark classes
    if (color == 'bg-white') {
      var textWhites = document.querySelectorAll('.sidenav .text-white');
      for (let i = 0; i < textWhites.length; i++) {
        textWhites[i].classList.remove('text-white');
        textWhites[i].classList.add('text-dark');
      }
    } else {
      var textDarks = document.querySelectorAll('.sidenav .text-dark');
      for (let i = 0; i < textDarks.length; i++) {
        textDarks[i].classList.add('text-white');
        textDarks[i].classList.remove('text-dark');
      }
    }

    if (color == 'bg-default' && bodyDark) {
      var textDarks = document.querySelectorAll('.navbar-brand .text-dark');
      for (let i = 0; i < textDarks.length; i++) {
        textDarks[i].classList.add('text-white');
        textDarks[i].classList.remove('text-dark');
      }
    }

    // Remove logo-white/logo-dark

    // if ((color == 'bg-white') && bodyWhite) {
    //   var navbarBrand = document.querySelector('.navbar-brand-img');
    //   var navbarBrandImg = navbarBrand.src;

    //   if (navbarBrandImg.includes('logo-ct.png')) {
    //     var navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
    //     navbarBrand.src = navbarBrandImgNew;
    //   }
    // } else {
    //   var navbarBrand = document.querySelector('.navbar-brand-img');
    //   var navbarBrandImg = navbarBrand.src;
    //   if (navbarBrandImg.includes('logo-ct-dark.png')) {
    //     var navbarBrandImgNew = navbarBrandImg.replace("logo-ct-dark", "logo-ct");
    //     navbarBrand.src = navbarBrandImgNew;
    //   }
    // }

    // if (color == 'bg-white' && bodyDark) {
    //   var navbarBrand = document.querySelector('.navbar-brand-img');
    //   var navbarBrandImg = navbarBrand.src;

    //   if (navbarBrandImg.includes('logo-ct.png')) {
    //     var navbarBrandImgNew = navbarBrandImg.replace("logo-ct", "logo-ct-dark");
    //     navbarBrand.src = navbarBrandImgNew;
    //   }
    // }
  }

  // Set Navbar Fixed
  window.navbarFixed = function(el) {
    let classes = ['position-sticky', 'bg-white', 'left-auto', 'top-2', 'z-index-sticky'];
    const navbar = document.getElementById('navbarBlur');

    if (!el.getAttribute("checked")) {
      toggleNavLinksColor('blur');
      navbar.classList.add(...classes);
      navbar.setAttribute('data-scroll', 'true');
      navbarBlurOnScroll('navbarBlur');
      el.setAttribute("checked", "true");
    } else {
      toggleNavLinksColor('transparent');
      navbar.classList.remove(...classes);
      navbar.setAttribute('data-scroll', 'false');
      navbarBlurOnScroll('navbarBlur');
      el.removeAttribute("checked");
    }
  };

  // Set Navbar Minimized
  window.navbarMinimize = function (el) {
    var sidenavShow = document.getElementsByClassName('g-sidenav-show')[0];

    if (!el.getAttribute("checked")) {
      sidenavShow.classList.remove('g-sidenav-pinned');
      sidenavShow.classList.add('g-sidenav-hidden');
      el.setAttribute("checked", "true");
    } else {
      sidenavShow.classList.remove('g-sidenav-hidden');
      sidenavShow.classList.add('g-sidenav-pinned');
      el.removeAttribute("checked");
    }
  }

  function toggleNavLinksColor(type) {
    let navLinks = document.querySelectorAll('.navbar-main .nav-link, .navbar-main .breadcrumb-item, .navbar-main .breadcrumb-item a, .navbar-main h6')
    let navLinksToggler = document.querySelectorAll('.navbar-main .sidenav-toggler-line')

    if (type === "blur") {
      navLinks.forEach(element => {
        element.classList.remove('text-white')
      });

      navLinksToggler.forEach(element => {
        element.classList.add('bg-dark')
        element.classList.remove('bg-white')
      });
    } else if (type === "transparent") {
      navLinks.forEach(element => {
        element.classList.add('text-white')
      });

      navLinksToggler.forEach(element => {
        element.classList.remove('bg-dark')
        element.classList.add('bg-white')
      });
    }
  }

  // Navbar blur on scroll
  function navbarBlurOnScroll(id) {
    const navbar = document.getElementById(id);
    let navbarScrollActive = navbar ? navbar.getAttribute("data-scroll") : false;
    let scrollDistance = 5;
    let classes = ['bg-white', 'left-auto', 'position-sticky'];
    let toggleClasses = ['shadow-none'];

    if (navbarScrollActive == 'true') {
      window.onscroll = debounce(function() {
        if (window.scrollY > scrollDistance) {
          blurNavbar();
        } else {
          transparentNavbar();
        }
      }, 10);
    } else {
      window.onscroll = debounce(function() {
        transparentNavbar();
      }, 10);
    }

    var isWindows = navigator.platform.indexOf('Win') > -1 ? true : false;

    if (isWindows) {
      var content = document.querySelector('.main-content');
      if (navbarScrollActive == 'true') {
        content.addEventListener('ps-scroll-y', debounce(function() {
          if (content.scrollTop > scrollDistance) {
            blurNavbar();
          } else {
            transparentNavbar();
          }
        }, 10));
      } else {
        content.addEventListener('ps-scroll-y', debounce(function() {
          transparentNavbar();
        }, 10));
      }
    }

    function blurNavbar() {
      navbar.classList.add(...classes)
      navbar.classList.remove(...toggleClasses)

      toggleNavLinksColor('blur');
    }

    function transparentNavbar() {
      navbar.classList.remove(...classes)
      navbar.classList.add(...toggleClasses)

      toggleNavLinksColor('transparent');
    }
  }

  // Debounce Function
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  // Tabs navigation

  var total = document.querySelectorAll('.nav-pills');

  // function initNavs() {
  //   total.forEach(function(item, i) {
  //     var moving_div = document.createElement('div');
  //     var first_li = item.querySelector('li:first-child .nav-link');
  //     var tab = first_li.cloneNode();
  //     tab.innerHTML = "-";

  //     moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
  //     moving_div.appendChild(tab);
  //     item.appendChild(moving_div);

  //     var list_length = item.getElementsByTagName("li").length;

  //     moving_div.style.padding = '0px';
  //     moving_div.style.width = item.querySelector('li:nth-child(1)').offsetWidth + 'px';
  //     moving_div.style.transform = 'translate3d(0px, 0px, 0px)';
  //     moving_div.style.transition = '.5s ease';

  //     item.onmouseover = function(event) {
  //       let target = getEventTarget(event);
  //       let li = target.closest('li'); // get reference
  //       if (li) {
  //         let nodes = Array.from(li.closest('ul').children); // get array
  //         let index = nodes.indexOf(li) + 1;
  //         item.querySelector('li:nth-child(' + index + ') .nav-link').onclick = function() {
  //           moving_div = item.querySelector('.moving-tab');
  //           let sum = 0;
  //           if (item.classList.contains('flex-column')) {
  //             for (var j = 1; j <= nodes.indexOf(li); j++) {
  //               sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
  //             }
  //             moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
  //             moving_div.style.height = item.querySelector('li:nth-child(' + j + ')').offsetHeight;
  //           } else {
  //             for (var j = 1; j <= nodes.indexOf(li); j++) {
  //               sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
  //             }
  //             moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
  //             moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
  //           }
  //         }
  //       }
  //     }
  //   });
  // }

  // setTimeout(function() {
  //   initNavs();
  // }, 100);

  // Tabs navigation resize

  // window.addEventListener('resize', function(event) {
  //   total.forEach(function(item, i) {
  //     item.querySelector('.moving-tab').remove();
  //     var moving_div = document.createElement('div');
  //     var tab = item.querySelector(".nav-link.active").cloneNode();
  //     tab.innerHTML = "-";

  //     moving_div.classList.add('moving-tab', 'position-absolute', 'nav-link');
  //     moving_div.appendChild(tab);

  //     item.appendChild(moving_div);

  //     moving_div.style.padding = '0px';
  //     moving_div.style.transition = '.5s ease';

  //     let li = item.querySelector(".nav-link.active").parentElement;

  //     if (li) {
  //       let nodes = Array.from(li.closest('ul').children); // get array
  //       let index = nodes.indexOf(li) + 1;

  //       let sum = 0;
  //       if (item.classList.contains('flex-column')) {
  //         for (var j = 1; j <= nodes.indexOf(li); j++) {
  //           sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
  //         }
  //         moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';
  //         moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
  //         moving_div.style.height = item.querySelector('li:nth-child(' + j + ')').offsetHeight;
  //       } else {
  //         for (var j = 1; j <= nodes.indexOf(li); j++) {
  //           sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
  //         }
  //         moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
  //         moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';

  //       }
  //     }
  //   });

  //   if (window.innerWidth < 991) {
  //     total.forEach(function(item, i) {
  //       if (!item.classList.contains('flex-column')) {
  //         item.classList.remove('flex-row');
  //         item.classList.add('flex-column', 'on-resize');
  //         let li = item.querySelector(".nav-link.active").parentElement;
  //         let nodes = Array.from(li.closest('ul').children); // get array
  //         let index = nodes.indexOf(li) + 1;
  //         let sum = 0;
  //         for (var j = 1; j <= nodes.indexOf(li); j++) {
  //           sum += item.querySelector('li:nth-child(' + j + ')').offsetHeight;
  //         }
  //         var moving_div = document.querySelector('.moving-tab');
  //         moving_div.style.width = item.querySelector('li:nth-child(1)').offsetWidth + 'px';
  //         moving_div.style.transform = 'translate3d(0px,' + sum + 'px, 0px)';

  //       }
  //     });
  //   } else {
  //     total.forEach(function(item, i) {
  //       if (item.classList.contains('on-resize')) {
  //         item.classList.remove('flex-column', 'on-resize');
  //         item.classList.add('flex-row');
  //         let li = item.querySelector(".nav-link.active").parentElement;
  //         let nodes = Array.from(li.closest('ul').children); // get array
  //         let index = nodes.indexOf(li) + 1;
  //         let sum = 0;
  //         for (var j = 1; j <= nodes.indexOf(li); j++) {
  //           sum += item.querySelector('li:nth-child(' + j + ')').offsetWidth;
  //         }
  //         var moving_div = document.querySelector('.moving-tab');
  //         moving_div.style.transform = 'translate3d(' + sum + 'px, 0px, 0px)';
  //         moving_div.style.width = item.querySelector('li:nth-child(' + index + ')').offsetWidth + 'px';
  //       }
  //     })
  //   }
  // });

  // Function to remove flex row on mobile devices
  if (window.innerWidth < 991) {
    total.forEach(function(item, i) {
      if (item.classList.contains('flex-row')) {
        item.classList.remove('flex-row');
        item.classList.add('flex-column', 'on-resize');
      }
    });
  }

  function getEventTarget(e) {
    e = e || window.event;
    return e.target || e.srcElement;
  }

  // End tabs navigation


  // click to minimize the sidebar or reverse to normal
  if (document.querySelector('.sidenav-toggler')) {
    var sidenavToggler = document.getElementsByClassName('sidenav-toggler')[0];
    var sidenavShow = document.getElementsByClassName('g-sidenav-show')[0];
    var toggleNavbarMinimize = document.getElementById('navbarMinimize');

    if (sidenavShow) {
      sidenavToggler.onclick = function() {
        if (!sidenavShow.classList.contains('g-sidenav-hidden')) {
          sidenavShow.classList.remove('g-sidenav-pinned');
          sidenavShow.classList.add('g-sidenav-hidden');
          if (toggleNavbarMinimize) {
            toggleNavbarMinimize.click();
            toggleNavbarMinimize.setAttribute("checked", "true");
          }
        } else {
          sidenavShow.classList.remove('g-sidenav-hidden');
          sidenavShow.classList.add('g-sidenav-pinned');
          if (toggleNavbarMinimize) {
            toggleNavbarMinimize.click();
            toggleNavbarMinimize.removeAttribute("checked");
          }
        }
      };
    }
  }


  // Toggle Sidenav
  const iconNavbarSidenav = document.getElementById('iconNavbarSidenav');
  const iconSidenav = document.getElementById('iconSidenav');
  const sidenav = document.getElementById('sidenav-main');
  let body = document.getElementsByTagName('body')[0];
  let className = 'g-sidenav-pinned';

  if (iconNavbarSidenav) {
    iconNavbarSidenav.addEventListener("click", toggleSidenav);
  }

  if (iconSidenav) {
    iconSidenav.addEventListener("click", toggleSidenav);
  }

  function toggleSidenav() {
    if (body.classList.contains(className)) {
      body.classList.remove(className);

      sidenav.classList.remove('bg-transparent');

    } else {
      body.classList.add(className);

      sidenav.classList.remove('bg-transparent');
      iconSidenav.classList.remove('d-none');
    }
  }


  // Resize navbar color depends on configurator active type of sidenav

  // let referenceButtons = document.querySelector('[data-class]');

  // window.addEventListener("resize", navbarColorOnResize);

  // function navbarColorOnResize() {
  //   if (sidenav) {
  //     if (window.innerWidth > 1200) {
  //       if (referenceButtons.classList.contains('active') && referenceButtons.getAttribute('data-class') === 'bg-default') {
  //         sidenav.classList.remove('bg-white');
  //       } else {
  //         if (!body.classList.contains('dark-version')) {
  //           sidenav.classList.add('bg-white');
  //         }
  //       }
  //     } else {
  //       sidenav.classList.add('bg-white');
  //       sidenav.classList.remove('bg-default');
  //     }
  //   }
  // }

  // // Deactivate sidenav type buttons on resize and small screens
  // window.addEventListener("resize", sidenavTypeOnResize);
  // window.addEventListener("load", sidenavTypeOnResize);

  // function sidenavTypeOnResize() {
  //   let elements = document.querySelectorAll('[onclick="sidebarType(this)"]');
  //   if (window.innerWidth < 1200) {
  //     elements.forEach(function(el) {
  //       el.classList.add('disabled');
  //     });
  //   } else {
  //     elements.forEach(function(el) {
  //       el.classList.remove('disabled');
  //     });
  //   }
  // }

  // Notification function
  function notify(el) {
    var body = document.querySelector('body');
    var alert = document.createElement('div');
    alert.classList.add('alert', 'position-absolute', 'top-0', 'border-0', 'text-white', 'w-50', 'end-0', 'start-0', 'mt-2', 'mx-auto', 'py-2');
    alert.classList.add('alert-' + el.getAttribute('data-type'));
    alert.style.transform = 'translate3d(0px, 0px, 0px)'
    alert.style.opacity = '0';
    alert.style.transition = '.35s ease';
    setTimeout(function() {
      alert.style.transform = 'translate3d(0px, 20px, 0px)';
      alert.style.setProperty("opacity", "1", "important");
    }, 100);

    alert.innerHTML = '<div class="d-flex mb-1">' +
      '<div class="alert-icon me-1">' +
      '<i class="' + el.getAttribute('data-icon') + ' mt-1"></i>' +
      '</div>' +
      '<span class="alert-text"><strong>' + el.getAttribute('data-title') + '</strong></span>' +
      '</div>' +
      '<span class="text-sm">' + el.getAttribute('data-content') + '</span>';

    body.appendChild(alert);
    setTimeout(function() {
      alert.style.transform = 'translate3d(0px, 0px, 0px)'
      alert.style.setProperty("opacity", "0", "important");
    }, 4000);
    setTimeout(function() {
      el.parentElement.querySelector('.alert').remove();
    }, 4500);
  }

})
