document.addEventListener('turbo:load', () => {
  $(document).ready(function() {
    $('#form-folder-state-selector').on('change', function() {
        window.location.search = "?state=" + $(this).val();
    });

    $('.form-folder-inclusion-checkbox').each(function() {
      $(this).on('change', function() {
          const checked = $(this).is(':checked');
          const formFolderId = $(this).data('form-folder-id');
          const formId = $(this).data('form-id');
          const csrfToken = $('meta[name="csrf-token"]').attr('content');

          $.ajax({
              url: "/form-folders/" + formFolderId + "/form",
              method: "POST",
              contentType: "application/json",
              headers: {
                  "X-CSRF-Token": csrfToken
              },
              data: JSON.stringify({
                  form_id: formId,
                  checked: checked
              }),
              success: function(sidebarHtml) {
                  $('#sidebar').html(sidebarHtml);
              }
          });
      });
    });
  });
})