export default {
  methods: {
    findItemIndex(id) {
      return this.data.findIndex(v => v.id == id)
    },
    removeItem(id) {
      let index = this.findItemIndex(id)

      if (index >= 0) {
        this.data.splice(index, 1)
      }
    }
  }
}
