<script>
  import LeadList from '../../components/leads/LeadList.vue'

  export default {
    name: 'ActiveLeads',
    mount_element: 'active-leads-app',
    components: { LeadList },
    data() {
      return {
        api_endpoint: '/api/leads.json'
      }
    },
  }
</script>

<template lang="pug">
LeadList(:api_endpoint="api_endpoint")
</template>
