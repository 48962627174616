<template lang="pug">
li.list-group-item.px-0.border-0
  .row.align-items-center
    .col-auto
      i.fas.fa-globe-americas
    .col
      p.text-xs.font-weight-bold.mb-0 New Lead:
      h6.text-sm.mb-0
        a(:href='leadUrl') {{ leadName }}
    .col.text-center
      p.text-xs.font-weight-bold.mb-0 Interested In:
      h6.text-sm.mb-0
        a(:href='inventoryUrl') {{ inventoryName }}
    .col.text-center
      p.text-xs.font-weight-bold.mb-0 From:
      h6.text-sm.mb-0 {{ origin }}
  hr.horizontal.dark.mt-3.mb-1
</template>

<script>
  import { get } from 'lodash'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      leadName() {
        return get(this, 'item.dealer_inquiry.full_name', '<Unknown>')
      },
      leadUrl() {
        const lead_id = get(this, 'item.dealer_inquiry.contact.id')
        if (!lead_id) { return null }

        return `/leads/${lead_id}/edit`
      },
      inventoryName() {
        return get(this, 'item.dealer_inquiry.inventory.simple_name', '<Unknown>')
      },
      inventoryUrl() {
        const inventory_id = get(this, 'item.dealer_inquiry.inventory.friendly_id')
        if (!inventory_id) { return null }

        return `/inventory/${inventory_id}/edit`
      },
      origin() {
        let frontline  = get(this, 'item.dealer_inquiry.frontline', 'Unknown')

        switch(frontline) {
          case true:
            return "Frontline"
          case false:
            return "Website"
          default:
            return "<Unknown>"
        }
      }
    },
    setup(props, { emit }) {
    }
  }
</script>

<style lang="sass" scoped>
</style>
