<template lang="pug">
InventoryItem(:item="item" v-if="item.object_type == 'inventory'")
DealerInquiryItem(:item="item" v-if="item.object_type == 'dealer_inquiry'")
MessageItem(:item="item" v-if="item.object_type == 'Message'")
CreditApplicationItem(:item="item" v-if="item.object_type == 'credit_application'")
CallItem(:item="item" v-if="item.object_type == 'account_call' || item.object_type == 'craigslist_call'")
</template>

<script>
  import InventoryItem from './InventoryItem.vue'
  import DealerInquiryItem from './DealerInquiryItem.vue'
  import MessageItem from './MessageItem.vue'
  import CreditApplicationItem from './CreditApplicationItem.vue'
  import CallItem from './CallItem.vue'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    components: { InventoryItem, DealerInquiryItem, MessageItem, CreditApplicationItem, CallItem },
    setup(props, { emit }) {
    }
  }
</script>

<style lang="sass" scoped>
</style>
