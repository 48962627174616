<script>
import DealList from "../../components/deals/DealList.vue";

export default {
  name: "BhphClosedDeal",
  mount_element: "close-deal-app",
  components: { DealList },
  data() {
    return {
      api_endpoint: "/deals/bhph/closed.json",
    };
  },
};
</script>

<template>
  <deal-list :api_endpoint="api_endpoint"></deal-list>
</template>
