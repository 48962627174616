import Overview from './overview/Overview.vue'
import ActiveInventory from './inventory/ActiveInventory.vue'
import ArchivedInventory from './inventory/ArchivedInventory.vue'
import InventoryGallery from './inventory-gallery/InventoryGallery.vue'
import ActiveLeads from './leads/ActiveLeads.vue'
import ArchivedLeads from './leads/ArchivedLeads.vue'
import ActiveVendors from './vendors/ActiveVendors.vue'
import ArchivedVendors from './vendors/ArchivedVendors.vue'
import LeadsInventoryGraph from './overview/LeadsInventoryGraph.vue'
import ActiveDeal from './deals/ActiveDeal.vue'
import ArchivedDeal from './deals/ArchivedDeal.vue'
import FormFolders from './form/FormFolders.vue'
import BhphDeal from './deals/BhphDeal.vue'
import BhphClosedDeal from './deals/BhphClosedDeal.vue'
export default [
  Overview,
  ActiveInventory,
  ArchivedInventory,
  InventoryGallery,
  ActiveLeads,
  ArchivedLeads,
  ActiveVendors,
  ArchivedVendors,
  LeadsInventoryGraph,
  ActiveDeal,
  ArchivedDeal,
  FormFolders,
  BhphDeal,
  BhphClosedDeal
]
