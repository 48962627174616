<template lang="pug">
tr
  td
    div(class="d-flex")
      a(:href="'/deals/' + deal.id + '/edit'")
        h6(class="ms-3 my-auto deal_nm") {{ deal.deal_name }}
  td
    span(class="badge bg-success ms-3 my-auto mt-1" v-if="deal.bhph_deal") bhph

  td(class="text-sm d_date") {{ deal.close_date }}
  td(class="text-sm text-center icon-container")
    a(v-if="deal.active" href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="archive" 
    @click.stop.prevent="archive")
      i(class="fas fa-archive text-secondary")

    a(v-else href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary")

    a(href="javascript:;" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary")

    a(v-if="deal.cont_present" :href="'/leads/' + deal.customer_id + '/edit'" class="me-3")
      i(class="fas fa-user text-secondary")
    a(v-if="deal.inv_present" :href="'/inventory/' + deal.inv_slug + '/edit'" class="me-3")
      i(class="fas fa-car text-secondary")

    a(v-if="deal.deal_closed" :href="'/deals/' + deal.id + '/open'" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Undo close" @click.stop.prevent="openDeal")
      span(class="badge badge-secondary") Undo close

    a(v-else :href="'/deals/' + deal.id + '/close'" class="me-3" data-method="post" data-bs-toggle="tooltip" data-bs-original-title="Sold? Close" @click.stop.prevent="closeDeal")
      span(class="badge sold_close") sold? close

    a(:href="'/deals/' + deal.id")
      span(class="badge d_overview") Overview

</template>


<script>

import archiveData from '../../hooks/archiveDeleteData'
import closeDealData from '../../hooks/closeDealData'
import openDealData from '../../hooks/openDealData'

export default {
  props: {
    deal: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {archive, unarchive, remove} = archiveData('/api/deals/', props.deal.id, emit)
    const {closeDeal} = closeDealData('/deals/', props.deal.id, emit)
    const {openDeal} = openDealData('/deals/', props.deal.id, emit)

    return { archive, unarchive, remove, closeDeal, openDeal }
  }
}
</script>


<style lang="sass" scoped>
  .icon-container
    text-align: right !important
    padding-right: 22px

  .d_date
    padding-left: 22px

  .deal_nm
    font-size: 0.8em !important

  .sold_close
    color: #f8fffc
    background-color: #12c376

  .d_overview
    color: #f8fffc
    background-color: #596cff

  .inventory-image
    width: 60px
    border-radius: 5px

  .no-inventory-image
    padding: 11px 0 10px 11px
    margin-right: 0.6rem
    font-size: 35px

  .img-section
    position: relative

    .img-counter
      position: absolute
      bottom: 0
      right: 0
      z-index: 10
      color: white
      font-weight: bold
      background: gray
      font-size: 14px
      opacity: 0.8
      border-radius: 5px 0 0 0
      padding: 0 1px 0
</style>
