<template lang="pug">
tr
  td(class="text-sm")
    a(:href="'/settings/vendors/' + vendor.id + '/edit'")
      h6(class="ms-3 my-auto")
        i(class="fas fa-user-tie text-secondary me-3")
        | {{ vendor.name }}

  td(class="text-sm") {{ vendor.phone }}
  td(class="text-sm text-center") {{ vendor.contact_person }}

  td(class="text-sm text-center")
    a(v-if="vendor.archived" href="#" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary")

    a(v-else href="#" data-bs-toggle="tooltip" data-bs-original-title="Archive" @click.stop.prevent="archive")
      i(class="fas fa-archive text-secondary")
</template>

<script>

import archiveData from '../../hooks/archiveDeleteData'

export default {
  props: {
    vendor: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {archive, unarchive} = archiveData('/api/vendors/', props.vendor.id, emit)

    return { archive, unarchive }
  }
}
</script>
