<template lang="pug">
li.list-group-item.px-0.border-0
  .row.align-items-center
    .col-auto
      i.fas.fa-search-dollar
    .col
      p.text-xs.font-weight-bold.mb-0 New Credit Application:
      h6.text-sm.mb-0 {{ leadName }}
    .col.text-center
      p.text-xs.font-weight-bold.mb-0 Interested In:
      h6.text-sm.mb-0
        a(:href='inventoryUrl') {{ inventoryName }}
    .col.text-center
    //-   p.text-xs.font-weight-bold.mb-0 From:
    //-   h6.text-sm.mb-0 {{ origin }}
  hr.horizontal.dark.mt-3.mb-1
</template>

<script>
  import { get } from 'lodash'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      leadName() {
        return get(this, 'item.credit_application.applicant.full_name')
      },
      inventoryName() {
        return get(this, 'item.credit_application.inventory.simple_name')
      },
      inventoryUrl() {
        const inventory_id = get(this, 'item.credit_application.inventory.friendly_id')
        if (!inventory_id) { return null }

        return `/inventory/${inventory_id}/edit`
      },
    },
    setup(props, { emit }) {
    }
  }
</script>

<style lang="sass" scoped>
</style>
