import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["payoffCheckbox", "payoffNestedSection", "payoffDestroyField"]

  payoffNestedSectionTargetConnected(element) {
    if (this.payoffCheckboxTarget.checked) {
      this.payoffNestedSectionTarget.classList.remove('hidden')
    } else {
      this.payoffNestedSectionTarget.classList.add('hidden')
    }
  }

  payoffDestroyFieldTargetConnected(element) {
    if (this.payoffCheckboxTarget.checked) {
      element.value = '0'
    } else {
      element.value = '1'
    }
  }

  toggle(event) {
    const checkbox = event.target
    const nested_section = this.payoffNestedSectionTarget
    const destroy_field = this.payoffDestroyFieldTarget

    console.log(nested_section)

    if (checkbox.checked) {
      nested_section.classList.remove('hidden')
      destroy_field.value = '0'
    } else {
      nested_section.classList.add('hidden')
      destroy_field.value = '1'
    }
  }

}
