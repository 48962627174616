<template lang="pug">
tr
  td
    div(class="d-flex")
        a(:href="'/form-folders/' + form.id + '/edit'")
          h6(class="text-sm fold_nm") {{ form.folder_name }}
  td(class="text-sm px-4") {{ form.form_count }}
  td(class="text-sm text-center icon-container")
    a(:href="'/form-folders/' + form.id")
      i(class="fa fa-bars text-secondary me-3" style="font-size: 16px")

    a(href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary")

</template>

<script>

import archiveData from '../../hooks/archiveDeleteData'

export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {archive, unarchive, remove} = archiveData('/api/form_folders', props.form.id, emit)

    return { archive, unarchive, remove }
  }
}
</script>
<style lang="sass" scoped>
  .icon-container
    text-align: center !important
  .fold_nm
    padding-left: 18px
</style>
