<template lang="pug">
tr
  td
    div(class="d-flex")
      div.img-section(v-if="vehicle.image_url")
        img(class="inventory-image ms-3" :src="vehicle.image_url")
        div.img-counter {{ vehicle.images_count }}
      i(class="fas fa-images no-inventory-image ms-3" v-else)
      a(:href="'/inventory/' + vehicle.id + '/edit'")
        h6(class="ms-3 my-auto") {{ vehicle.simple_name }}
        div(class="inventory-tag-list")
          span(class="badge bg-gradient-primary" v-for="tag_name in vehicle.tag_list") {{ tag_name }}

  td(class="text-sm") {{ vehicle.vin }}
  td(class="text-sm") {{ vehicle.price }}
  td(class="text-sm text-center") {{ vehicle.stock_number }}

  td(class="text-sm text-center")
    a(v-if="vehicle.archived" href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Unarchive" @click.stop.prevent="unarchive")
      i(class="fas fa-undo text-secondary")

    a(v-else href="#" class="me-3" data-bs-toggle="tooltip" data-bs-original-title="Archive" @click.stop.prevent="archive")
      i(class="fas fa-archive text-secondary")

    a(href="javascript:;" data-bs-toggle="tooltip" data-bs-original-title="Delete" @click.stop.prevent="remove")
      i(class="fas fa-trash text-secondary")
</template>

<script>

import archiveData from '../../hooks/archiveDeleteData'

export default {
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const {archive, unarchive, remove} = archiveData('/api/inventory/', props.vehicle.id, emit)

    return { archive, unarchive, remove }
  }
}
</script>

<style lang="sass" scoped>
  .inventory-image
    width: 60px
    border-radius: 5px

  .no-inventory-image
    padding: 11px 0 10px 11px
    margin-right: 0.6rem
    font-size: 35px

  .img-section
    position: relative

    .img-counter
      position: absolute
      bottom: 0
      right: 0
      z-index: 10
      color: white
      font-weight: bold
      background: gray
      font-size: 14px
      opacity: 0.8
      border-radius: 5px 0 0 0
      padding: 0 1px 0
</style>
