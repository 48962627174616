$('#show_service_cost_link').click(function(e) {
  e.preventDefault();

  $('#service_contract_cost_wrapper').toggleClass('hidden');
  var linkText = $('#service_contract_cost_wrapper').hasClass('hidden') ? 'Show cost' : 'Hide cost';
  $(this).text(linkText);
  if (linkText === 'Hide cost') {
    $(this).removeClass('blue-text').addClass('red-text');
  } else {
    $(this).removeClass('red-text').addClass('blue-text');
  }
});
class ContractUI {
  clear_vsc_output_fields() {
    $('#deal_service_contract_attributes_selling_price').val(0);
    $('#deal_service_contract_attributes_cost').val(0);
    $('#deal_service_contract_attributes_deductible').val('');
    $('#deal_service_contract_attributes_months').val('');
    $('#deal_service_contract_attributes_miles').val('');
    $('#deal_service_contract_attributes_external_rate_code').val('');
    calculate_financing();
  }
}

class NoneUI extends ContractUI {
  show() {
    // $('#no_service_contract').show();
    $('.no-vsc-link-block').hide();
    $('#no_service_contract input').prop('disabled', false);
  }

  hide() {
    $('#no_service_contract').hide();
    $('.no-vsc-link-block').show();
    this.disable();
  }

  disable() {
    $('#no_service_contract input').prop('disabled', true);
  }
}

class BasicUI extends ContractUI {
  show() {
    $('#basic_service_contract').show();
    $('.other-vsc-link-block').hide();
    $('#basic_service_contract input').prop('disabled', false);
  }

  hide() {
    $('#basic_service_contract').hide();
    $('.other-vsc-link-block').show();
    this.disable();
  }

  disable() {
    $('#basic_service_contract input').prop('disabled', true);
  }
}

class ExternalUI extends ContractUI {
  bindings() {
    $('.change-vsc-link-from-existing').on('click', () => {
      $('.change-vsc-link-from-existing').hide();
      $('.change-vsc').show();
      $('#external_service_contract .blank-state').show();
      this.get_deal_vsc_options();
    });

    $('#vsc_deductible_amount_sel, #vsc_deductible_type_sel, #vsc_combined_fields_sel').on('change', () => {
      this.update_vsc_dropdowns(false);
      this.update_vsc_output_fields();
    });

    $('.try-again-link').on('click', (e) => {
      e.preventDefault();
      this.reload_vsc_rates();
    });

    $('body').on('change', '.gwc-surcharge-checkbox', this.gwc_surcharge_checkbox_toggle);
  }

  show() {
    this.showed_gwc_surcharges = false;
    $('.change-vsc, .change-vsc .fields, .blank-state, .error-state, .not-selected-state').hide();
    $('.selling-price-block').hide();
    $('.show-vsc').show();

    if ($('#external_service_contract').data('selected-vsc')) {
      $('.selling-price-block').show();
    }

    if ($('#deal_vehicle_id').val() == '') {
      $('#external_service_contract .not-selected-state').show();
    }

    if ($('#external_service_contract').data('newRecord') && !$('#external_service_contract').data('selected-vsc')) {
      $('.show-vsc').hide();
      $('.change-vsc').show();
      this.get_deal_vsc_options();
    }

    if (!$('#external_service_contract').data('newRecord')) {
      if (!$('#external_service_contract').data('selected-vsc')) {
        $('.show-vsc').hide();
        $('.change-vsc').show();
        this.get_deal_vsc_options();
      }
    }

    $('#external_service_contract').show();
    $('.external-vsc-link-block').hide();
  }

  hide() {
    this.cancel_external_request();
    $('#external_service_contract').hide();
    this.clear_vsc_dropdowns();
    this.clear_vsc_output_fields();
    this.disable();
    calculate_financing();
    $('.external-vsc-link-block').show();
  }

  disable() {
    $('#external_override_fields').prop('disabled', true);
    $('#external_common_fields input').prop('disabled', true);
  }

  enable() {
    $('#external_override_fields').prop('disabled', false);
    $('#external_common_fields input').prop('disabled', false);
  }

  clear_vsc_dropdowns() {
    $("#vsc_deductible_amount_sel, #vsc_deductible_type_sel, #vsc_combined_fields_sel").empty();
  }
  get_deal_vsc_options() {
    this.disable();
    this.clear_portfolio_fields();

    $('#eac_error, #gwc_error').hide();

    const vehicle_id = $('#deal_vehicle_id').val();
    if (vehicle_id) {
      $('#external_service_contract .blank-state').show();

      window.rates_request = $.get(`/vsc/${vehicle_id}.json`, (data) => {
        this.enable();

        this.portfolio_error = data.portfolio_error;

        if (this.portfolio_error !== '' && this.portfolio_error !== null) {
          $('#eac_error .eac-error-text').text(this.portfolio_error);
          $('#eac_error').show();
        }

        if (this.portfolio_error) {
          this.rate_request_failed();
        } else {
          $('#external_service_contract .fields').show();
          $('.selling-price-block').show();
          $('.no-vsc-link-block').show();

          this.rates = data.rates;

          const default_code = $('#external_service_contract').data('selected-vsc') || data.default.rate_code;
          const default_deductible = $('#external_service_contract').data('selected-deductible') || data.default.deductible_amount;

          const default_option = $.grep(this.rates, (e) => e.rate_code === default_code && e.deductible_amount === parseInt(default_deductible))[0];
          this.selected_coverage = default_option.coverage;
          this.selected_deductible_amount = parseInt(default_option.deductible_amount);
          this.selected_deductible_type = default_option.deductible_type;
          this.selected_code = default_option.rate_code;

          this.clear_vsc_dropdowns();
          this.update_vsc_dropdowns(true);

          $("#vsc_deductible_amount_sel").val(this.selected_deductible_amount);
          $("#vsc_deductible_type_sel").val(this.selected_deductible_type);
          $("#vsc_combined_fields_sel").val(this.selected_code);

          this.update_vsc_output_fields();
        }
      }).fail((hxr) => {
        this.rate_request_failed();

        if (hxr.status === 422) {
          $('#external_service_contract .error-state').show();
          $('#external_service_contract .error-state .error-text').text($.parseJSON(hxr.responseText).errors);
        } else {
          $('#external_service_contract .error-state').show();
          $('#external_service_contract .error-state .error-text').text("Couldn't get rates.");
        }
      }).always(() => {
        $('#external_service_contract .blank-state').hide();
      });
    }
  }

  rate_request_failed() {
    this.disable();
    this.clear_portfolio_fields();
    $('#external_common_fields input').val('');
  }

  cancel_external_request() {
    if (window.rates_request !== undefined) {
      window.rates_request.abort();
    }
  }

  reload_vsc_rates() {
    const sc_type = window.service_contract_type;

    if (sc_type === 'portfolio' || sc_type === 'gwc' || sc_type === 'external') {
      this.cancel_external_request();

      if ($('#deal_vehicle_id').val()) {
        $('#external_service_contract .error-state, #external_service_contract .not-selected-state').hide();
        $('#external_service_contract .fields').hide();
        $('.selling-price-block').hide();
        $('#external_service_contract .blank-state').show();
        this.get_deal_vsc_options();
      } else {
        $('#external_service_contract .fields').hide();
        $('.selling-price-block').hide();
        $('#external_service_contract .error-state, #external_service_contract .blank-state').hide();
        $('#external_service_contract .not-selected-state').show();
      }
    }
  }

  update_vsc_dropdowns(first) {
    if (first) {
      const deductible_columns = [...new Set(this.rates.map((o) => o['deductible_amount']))].sort((a, b) => a - b);
      const deductible_type_columns = [...new Set(this.rates.map((o) => o['deductible_type']))].sort();
      $("#vsc_deductible_amount_sel").empty();
      $("#vsc_deductible_type_sel").empty();
      for (const o of deductible_columns) {
        $("#vsc_deductible_amount_sel").append($("<option />").val(parseInt(o)).text(`$${numberWithCommas(parseInt(o))} deductible`));
      }
      for (const o of deductible_type_columns) {
        $("#vsc_deductible_type_sel").append($("<option />").val(o).text(`${capitalizeFirstLetter(o.toLowerCase())}`));
      }
    }

    if (!first) {
      this.selected_deductible_amount = $("#vsc_deductible_amount_sel").val();
      this.selected_deductible_type = $("#vsc_deductible_type_sel").val();
    }

    this.deductible_filtered_data = [];
    for (const d of this.rates) {
      if (parseInt(d.deductible_amount) === parseInt(this.selected_deductible_amount) && d.deductible_type === this.selected_deductible_type) {
        this.deductible_filtered_data.push(d);
      }
    }

    $("#vsc_combined_fields_sel").empty();

    for (const [i, d] of this.deductible_filtered_data.entries()) {
      let s = `$${numberWithCommas(parseInt(d.price))} - ${d.term_months} months - `;
      if (d.provider === 'portfolio') {
        s = s + `${numberWithCommas(parseInt(d.real_miles))}/${d.term_miles_text} miles`;
      } else if (d.provider === 'gwc') {
        s = s + `${numberWithCommas(parseInt(d.term_miles))} miles`;
      }

      s = s + ` (${d.provider_text} - ${d.coverage})`;

      $("#vsc_combined_fields_sel").append($("<option />").val(d.rate_code).text(s));
    }

    const last_option = this.last_option;

    if (!first) {
      let new_option;
      if (last_option) {
        new_option = $.grep(this.deductible_filtered_data, (e) => e.term_months === last_option.term_months && e.term_miles === last_option.term_miles)[0];
      }

      if (new_option) {
        $("#vsc_combined_fields_sel").val(new_option.rate_code);
      } else {
        $("#vsc_combined_fields_sel option:first").attr("selected", "selected");
      }
    }
  }

  disable() {
    $('#external_override_fields').prop('disabled', true);
    $('#external_common_fields input').prop('disabled', true);
  }

  enable() {
    $('#external_override_fields').prop('disabled', false);
    $('#external_common_fields input').prop('disabled', false);
  }

  clear_vsc_dropdowns() {
    $("#vsc_deductible_amount_sel, #vsc_deductible_type_sel, #vsc_combined_fields_sel").empty();
  }

  update_vsc_output_fields() {
    this.clear_portfolio_fields();
    $('.gwc-breakdown').hide();

    const code = $('#vsc_combined_fields_sel').val();
    const option = this.get_selected_option();
    const provider = option.provider;

    // Basic fields
    $('#deal_service_contract_attributes_selling_price').val(+option.price + 500);
    $('#deal_service_contract_attributes_cost').val(+option.price);
    $('#deal_service_contract_attributes_deductible').val(option.deductible_amount);
    $('#deal_service_contract_attributes_months').val(option.term_months);
    $('#deal_service_contract_attributes_miles').val(option.term_miles);

    $('#external_service_contract_type').val(provider);
    $('#external_plan_type_text').val(option.coverage);

    if (provider === 'portfolio') {
      $(".coverage_info").attr('href', "http://motorlot.com/expressautocare.pdf");

      if (this.last_provider !== provider) {
        $(".coverage_info").effect('highlight');
      }

      $('#deal_service_contract_attributes_external_rate_code').val(code);
      $('#external_responsible_user').val($('#external_service_contract').data('current-user'));
    } else if (provider === 'gwc') {
      $(".coverage_info").attr('href', "http://motorlot.com/gwcwarranty.pdf");

      if (this.last_provider !== provider) {
        $(".coverage_info").effect('highlight');
      }

      // Show GWC breakdown
      this.show_gwc_breakdown(option);

      $('#deal_service_contract_attributes_gwc_vsc_code').val(code);
      $('#deal_service_contract_attributes_gwc_vsc_company').val(option.rate_company);
      $('#deal_service_contract_attributes_gwc_vsc_dealer_cost').val(option.rate_price);
      $('#deal_service_contract_attributes_gwc_deductible_code').val(option.deductible_code);
      $('#deal_service_contract_attributes_gwc_deductible_description').val(option.deductible_description);
      $('#deal_service_contract_attributes_gwc_deductible_cost').val(option.deductible_price);
    }

    calculate_financing();

    this.last_option = option;
    this.last_provider = this.last_option.provider;
  }
  clear_portfolio_fields() {
    $('#portfolio_unique_fields input').val('');
  }

  generate_price_line_html(name, value, total, optional, checked) {
    let div_class = 'price-line';

    if (total) {
      div_class += ' total';
    }

    let checkbox = '';
    if (optional) {
      checkbox = checked ? "<input type='checkbox' checked='checked' class='gwc-surcharge-checkbox' data-sname='" + name + "'>" : "<input type='checkbox' class='gwc-surcharge-checkbox' data-sname='" + name + "'>";
    }

    let result = "<div class='" + div_class + "'>";
    result += "<span class='price-text'>" + checkbox + name + "</span>";
    result += "<span class='price-value'>$" + value + "</span>";
    result += "</div>";

    return result;
  }

  generate_surcharge_input_html(s, i) {
    return `
      <input type='hidden' name="deal[service_contract_attributes][gwc_surcharges][${i}][code]" value='${s['code']}'>
      <input type='hidden' name="deal[service_contract_attributes][gwc_surcharges][${i}][description]" value='${s['description']}'>
      <input type='hidden' name="deal[service_contract_attributes][gwc_surcharges][${i}][price]" value='${s['price']}'>
    `;
  }

  show_gwc_breakdown(option) {
    if (option['surcharges'] && option['surcharges'].length) {
      $('.breakdown-container').html('');

      const base_price = option['rate_price'] + option['deductible_price'];
      $('.breakdown-container').append(this.generate_price_line_html('Base', base_price, false));

      this.gwc_surcharges = option['surcharges'];

      this.gwc_surcharges.forEach((s, i) => {
        if (s['type'] == 'Mandatory') {
          $('.breakdown-container').append(this.generate_price_line_html(`${s['description']}`, s['price'], false, false));
          $('#gwc_surcharges').append(this.generate_surcharge_input_html(s, i));
          s['selected'] = true;
        }
      });

      option['surcharges'].forEach((s, i) => {
        if (s['type'] == 'Optional') {
          if (!this.showed_gwc_surcharges && initial_gwc_surcharges.length && initial_gwc_surcharges.includes(s['description'])) {
            $('.breakdown-container').append(this.generate_price_line_html(`${s['description']}`, s['price'], false, true, true));
            s['selected'] = true;
          } else {
            s['selected'] = false;
            $('.breakdown-container').append(this.generate_price_line_html(`${s['description']}`, s['price'], false, true));
          }
        }
      });

      this.showed_gwc_surcharges = true; // Showed initial surcharges
      this.calculate_gwc_breakdown_total();
      this.set_gwc_breakdown_output_fields();

      $('.gwc-breakdown').show();
    }
  }

  clear_gwc_breakdown_fields() {
    $('#gwc_unique_fields #gwc_surcharges').html('');
  }

  calculate_gwc_breakdown_total() {
    this.clear_gwc_breakdown_fields();
    $('.price-line.total').remove();

    const option = this.get_selected_option();
    const base_price = option['rate_price'] + option['deductible_price'];
    let total_price = base_price;

    this.gwc_surcharges.forEach((s, i) => {
      if (s['selected']) {
        total_price += s['price'];
      }
    });

    $('.breakdown-container').append(this.generate_price_line_html('Total', total_price, true, false));
    $('#deal_service_contract_attributes_selling_price').val(total_price + 500);
    calculate_financing();
  }

  set_gwc_breakdown_output_fields() {
    this.clear_gwc_breakdown_fields();

    this.gwc_surcharges.forEach((s, i) => {
      if (s['selected']) {
        $('#gwc_surcharges').append(this.generate_surcharge_input_html(s, i));
      }
    });
  }

  get_selected_option() {
    const code = $('#vsc_combined_fields_sel').val();
    const deductible = $("#vsc_deductible_amount_sel").val();

    return $.grep(this.rates, (e) => e.rate_code === code && e.deductible_amount === parseInt(deductible))[0];
  }

  gwc_surcharge_checkbox_toggle(e) {
    const $checkbox = $(e.target);
    const checked = $checkbox.is(':checked');
    const name = $checkbox.data('sname');

    this.gwc_surcharges.forEach((s, i) => {
      if (s['description'] === name) {
        s['selected'] = checked;
      }
    });

    this.calculate_gwc_breakdown_total();
    this.set_gwc_breakdown_output_fields();
  }
}
const none_ui = new NoneUI();
const basic_ui = new BasicUI();
const external_ui = new ExternalUI();

// Deal overview screen
const vsc_loading_state = () => {
  $('#vsc_pending, #vsc_failure, #vsc_ready, #vsc_internal_error, #vsc_manual, #no_gwc_id').hide();
  $('#vsc_loading').show();
};

const vsc_internal_error_state = () => {
  $('#vsc_internal_error').fadeIn();
};

const vsc_state_reload = (id) => {
  vsc_loading_state();

  const jqxhr = $.get(`/service_contracts/${id}`, (data) => {
    $('#vsc_loading').hide();
    const state = data['service_contract']['state'];
    const pdf_url = data['pdf_url'];

    if (pdf_url || state == 'success') {
      $('#vsc_pdf_link').prop('href', pdf_url);
      $('#vsc_ready').fadeIn();
    } else if (state == 'failure') {
      const last_error = data['service_contract']['last_error'];
      if (last_error) {
        $('#vsc_detailed_error').html(last_error.replace(/\n/g, "<br>"));
        $('#vsc_detailed_error').fadeIn();
      } else {
        $('#vsc_detailed_error').hide();
      }
      $('#vsc_failure').fadeIn();
    } else if (state == 'queued' || state == 'enrolling') {
      $('#vsc_pending').fadeIn();
      const callback = () => vsc_state_reload(id);
      setTimeout(callback, 5000);
    } else if (state == 'no_gwc_id') {
      $('#no_gwc_id').fadeIn();
    } else {
      $('#vsc_manual').fadeIn();
    }
  })
  .fail(() => {
    vsc_internal_error_state();
  })
  .always(() => {
    $('#vsc_loading').hide();
  });
};

const arrNoDupe = (a) => {
  const temp = {};
  let i = 0;
  while (i < a.length) {
    temp[a[i++]] = true;
  }
  return Object.keys(temp);
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const capitalizeFirstLetter = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

$(document).ready(() => {
  $('#deal_type_of_sale_wholesale').on('click', () => {
    setTimeout(() => {
      $('.no-vsc-link').click();
      $('#deal_accessories').val('0.00');
    }, 0);
  });

  window.service_contract_type = $('#service_contract').data('contract-type');

  $('#deal_vehicle_id').on('change', () => {
    $('#external_service_contract').data('selected-vsc', null);
    $('#external_service_contract').data('selected-deductible', null);
    external_ui.reload_vsc_rates();
  });

  // portfolio bindings
  external_ui.bindings();

  // switch to external contract
  $('.external-vsc-link').on('click', () => {
    window.service_contract_type = 'external';
    none_ui.hide();
    basic_ui.hide();
    external_ui.show();
  });

  // switch to no contract
  $('.no-vsc-link').on('click', () => {
    window.service_contract_type = 'empty';
    none_ui.show();
    basic_ui.hide();
    external_ui.hide();
  });

  // switch to other contract
  $('.other-vsc-link').on('click', () => {
    window.service_contract_type = 'basic';
    none_ui.hide();
    basic_ui.show();
    external_ui.hide();
  });

  // General VSC init
  external_ui.disable();
  basic_ui.disable();
  none_ui.disable();

  const sc_type = window.service_contract_type;
  if (sc_type == 'portfolio' || sc_type == 'gwc' || sc_type == 'external') {
    external_ui.show();
  } else if (sc_type == 'basic') {
    basic_ui.show();
  } else {
    none_ui.show();
  }
});