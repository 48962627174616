<script>
  import loadData from '../../hooks/loadData'

  import Spinner from '../../components/Spinner.vue'
  import OverviewItem from '../../components/overview/OverviewItem.vue'

  export default {
    name: 'Overview',
    mount_element: 'overview-app',
    components: { Spinner, OverviewItem },
    data() {
      return {
        items: []
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {

    },
    setup() {
      let api_endpoint = '/api/overview.json'
      const { fetchData, fetchAdditionalData, data, loading, currentPage, totalPages } = loadData(api_endpoint, 25)

      return {
        data,
        fetchData,
        fetchAdditionalData,
        loading,
        currentPage,
        totalPages
      }
    }
  }
</script>

<template lang="pug">
ul.list-group.list-group-flush.list.my--3
  OverviewItem(v-for='item in data' :item='item' :key='item.id')
<Spinner v-if="loading"></Spinner>
div(v-intersection="fetchAdditionalData" class="observer")
</template>
