<script>
  import InventoryList from '../../components/inventory/InventoryList.vue';

  export default {
    name: 'ArchivedInventory',
    mount_element: 'archived-inventory-app',
    components: { InventoryList },
    data() {
      return {
        api_endpoint: '/api/inventory/archived.json'
      }
    },
  }
</script>

<template>
  <inventory-list :api_endpoint="api_endpoint"></inventory-list>
</template>
