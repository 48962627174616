<template lang="pug">
div(class="table-responsive")
  table(class="table align-items-center")
    thead
      tr
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Name
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Status
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Created At
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Phone
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Home phone
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Business phone
        th.text-uppercase.text-secondary.text-xxs.font-weight-bolder.opacity-7 Action
    tbody
      LeadItem(v-for='lead in data' :lead='lead' :key='lead.id' @remove="removeItem" @archive="removeItem" @unarchive="removeItem")

div(v-intersection="fetchAdditionalData" class="observer")
Spinner(v-if="loading")
</template>

<script>
import LeadItem from './LeadItem.vue'
import Spinner from '../Spinner.vue'
import loadData from '../../hooks/loadData'
import dataHelpers from '../../mixins/dataHelpers'

export default {
  data() {
    return {
    }
  },
  components: { LeadItem, Spinner },
  props: {
    api_endpoint: {
      type: String,
      required: true
    }
  },
  mixins: [dataHelpers],
  mounted() {
    this.fetchData()
  },
  setup(props) {
    const { fetchData, fetchAdditionalData, data, loading, currentPage, totalPages } = loadData(props.api_endpoint, 25)

    return {
      data,
      fetchData,
      fetchAdditionalData,
      loading,
      currentPage,
      totalPages
    }
  }
}
</script>
