<template>
  <div>
    <div v-if="!loaded" class="loader"></div>
    <Bar
      v-else
      id="my-chart-id"
      :data="chartData"
      :options="chartOptions"
      :height="320"
    />
  </div>
</template>
<style>
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>

<script>
import { Bar } from "vue-chartjs";
import { ref } from "vue";
import axios from "axios";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "LeadsInventoryGraph",
  mount_element: "leads-inventory-graph-app",
  components: { Bar },
  data() {
    const loaded = ref(false);
    const responseDataInventory = ref([]);
    const responseDataLeads = ref([]);
    const dateRangeInput = $('#dateRangeInput').val();

    return {
      exampleValue: null,
      loaded,
      responseDataInventory,
      responseDataLeads,
      dateRange: dateRangeInput,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              beginAtZero: true,
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const currentDate = new Date();
      var labels;
      const myDivElement = document.getElementById('leads-inventory-graph-app');
      if (myDivElement)
      {
        const nameAttributeValue = myDivElement.getAttribute('data-name');
        if (nameAttributeValue === 'mtd'){
          const startOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          );

          labels = Array.from(
            { length: currentDate.getDate() },
            (_, index) => {
              const date = new Date(startOfMonth);
              date.setDate(startOfMonth.getDate() + index);
              return date.getDate();
            }
          );
        }
        if (nameAttributeValue === 'qtd'){
          const currentQuarter = this.getCurrentQuarter();
          labels = [currentQuarter];
        }
        if (nameAttributeValue === 'month'){
          const last30Days = new Date(currentDate - 29 * 24 * 60 * 60 * 1000);
      
          labels = Array.from({ length: 30 }, (_, index) => {
            const date = new Date(last30Days);
            date.setDate(date.getDate() + index);
            return date.getDate();
          });
        }
        if(nameAttributeValue === 'ytd'){
          const startOfYear = new Date(currentDate.getFullYear(), 0, 1);

          labels = [];
          
          for (let index = 0; index < Math.ceil((currentDate - startOfYear) / (24 * 60 * 60 * 1000)); index++) {
            const date = new Date(startOfYear);
            date.setDate(startOfYear.getDate() + index);
            const monthLabel = date.toLocaleString('default', { month: 'short' });

            if (!labels.includes(monthLabel)) {
              labels.push(monthLabel);
            }
          }
        }
        if(nameAttributeValue === 'date_range'){
          const urlSearchParams = new URLSearchParams(window.location.search);
          const startDateParam = urlSearchParams.get("start_date");
          const endDateParam = urlSearchParams.get("end_date");

          const startDate = new Date(startDateParam);
          const endDate = new Date(endDateParam);

          labels = this.generateDateLabels(startDate, endDate);
        }
        if (nameAttributeValue === 'week'){
          const last30Days = new Date(currentDate - 6 * 24 * 60 * 60 * 1000);

          labels = Array.from({ length: 7 }, (_, index) => {
            const date = new Date(last30Days);
            date.setDate(date.getDate() + index);
            return date.getDate();
          });
        }
        if(nameAttributeValue === '21d'){
          const last21Days = new Date(currentDate - 21 * 24 * 60 * 60 * 1000);

          labels = Array.from({ length: 21 }, (_, index) => {
            const date = new Date(last21Days);
            date.setDate(date.getDate() + index);
            return date.getDate();
          });
        }
      } else {
        console.warn('Element with ID not found.');
      }

      const datasets = [
        {
          label: "Inventory",
          backgroundColor: "#825ee4",
          data: Object.values(this.responseDataInventory),
        },
        {
          label: "Leads",
          backgroundColor: "#f56036",
          data: Object.values(this.responseDataLeads),
        },
      ];

      return {
        labels,
        datasets,
      };
    },
  },
  methods: {
    getCurrentQuarter() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentQuarter = Math.ceil(currentMonth / 3);

      return `Q${currentQuarter}`;
    },
    generateDateLabels(startDate, endDate) {
      const labels = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        labels.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return labels;
    },
  },
  async mounted() {
    try {
      const myDivElement = document.getElementById('leads-inventory-graph-app');
      var inventoryResponse;
      var leadsResponse;
      if (myDivElement)
      {
        const nameAttributeValue = myDivElement.getAttribute('data-name');
        if (nameAttributeValue === 'mtd'){
          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get("/api/inventory/month_to_date.json"),
            axios.get("/api/leads/month_to_date.json"),
          ]);
        }
        if (nameAttributeValue === '21d'){
          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get("/api/inventory/last21_days_counts.json"),
            axios.get("/api/leads/last21_days_counts.json"),
          ]);
        }
        if (nameAttributeValue === 'ytd'){
          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get("/api/inventory/year_to_date.json"),
            axios.get("/api/leads/year_to_date.json"),
          ]);
        }
        if (nameAttributeValue === 'qtd'){
          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get("/api/inventory/quarter_to_date.json"),
            axios.get("/api/leads/quarter_to_date.json"),
          ]);
        }
        if (nameAttributeValue === 'week'){
          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get("/api/inventory/weekly_counts.json"),
            axios.get("/api/leads/weekly_counts.json"),
          ]);
        }
        if (nameAttributeValue === 'month'){
          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get("/api/inventory/monthly_counts.json"),
            axios.get("/api/leads/monthly_counts.json"),
          ]);
        }
        if(nameAttributeValue === 'date_range'){
          const urlSearchParams = new URLSearchParams(window.location.search);
          const startDateParam = urlSearchParams.get("start_date");
          const endDateParam = urlSearchParams.get("end_date");

          this.startDate = startDateParam;
          this.endDate = endDateParam;

          [inventoryResponse, leadsResponse] = await Promise.all([
            axios.get(`/api/inventory/date_range_count.json?start_date=${startDateParam}&end_date=${endDateParam}`),
            axios.get(`/api/leads/date_range_count.json?start_date=${startDateParam}&end_date=${endDateParam}`),
          ]);
        }
        console.log('Name attribute value:', nameAttributeValue);
      } else {
        console.warn('Element with ID not found.');
      }

      this.responseDataInventory = inventoryResponse.data;
      this.responseDataLeads = leadsResponse.data;

      this.loaded = true;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
