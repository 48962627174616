import { createApp } from 'vue/dist/vue.esm-bundler'
import apps from './apps/index'

import VIntersection from './directives/VIntersection'

document.addEventListener('turbo:load', () => {
  apps.forEach(app_class => {
    if (document.getElementById(app_class.mount_element)) {
      let app = createApp(app_class)
      app.directive('intersection', VIntersection)
      app.mount('#' + app_class.mount_element)
    }
  })
})

// this event listener is executed as soon as
// the new body was fetched successfully but
// before replacing the `document.body`
document.addEventListener('turbo:before-cache', () => {
  if (typeof app !== 'undefined') app.unmount();
});
