<script>
import DealList from "../../components/deals/DealList.vue";

export default {
  name: "ArchivedDeal",
  mount_element: "archived-deal-app",
  components: { DealList },
  data() {
    return {
      api_endpoint: "/api/deals/archived.json",
    };
  },
};
</script>

<template>
  <deal-list :api_endpoint="api_endpoint"></deal-list>
</template>
