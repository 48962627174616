<script>
  import FormFoldersList from '../../components/form/FormFoldersList.vue'

export default {
  name: "FormFolders",
  mount_element: "form-folders-app",
  components: { FormFoldersList },
  data() {
    return {
      api_endpoint: "/api/form_folders.json",
    };
  },
};
</script>

<template lang="pug">
FormFoldersList(:api_endpoint="api_endpoint")
</template>
