<template lang="pug">
li.list-group-item.px-0.border-0
  .row.align-items-center
    .col-auto
      i.fas.fa-sms
    .col
      p.text-xs.font-weight-bold.mb-0 New SMS From:
      h6.text-sm.mb-0
        a(:href='leadUrl') {{ leadName }}
  hr.horizontal.dark.mt-3.mb-1
</template>

<script>
  import { get } from 'lodash'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      leadName() {
        return get(this, 'item.message.sender.full_name', '<No Name>')
      },
      leadUrl() {
        const lead_id = get(this, 'item.message.sender.id')
        if (!lead_id) { return null }

        return `/leads/${lead_id}/edit`
      }
    },
    setup(props, { emit }) {
    }
  }
</script>

<style lang="sass" scoped>
</style>
