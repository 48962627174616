import axios from "axios";
import { ref } from "vue";

export default function loadData(api_endpoint, perPage) {
  const data = ref([])
  const loading = ref(false)
  const currentPage = ref(1)
  const totalPages = ref(0)

  const fetchData = async () => {
    try {
      loading.value = true

      const response = await axios.get(api_endpoint, { params: {"page": currentPage.value } })
      totalPages.value = Math.ceil(response['headers']['x-total-count'] / perPage)
      data.value = response.data


    } catch(e) {
      alert('An error occurred while retrieving data.')
    } finally {
      loading.value = false
    }
  }

  const fetchAdditionalData = async () => {
    try {
      if (currentPage.value < totalPages.value) {
        loading.value = true

        currentPage.value += 1
        const response = await axios.get(api_endpoint, { params: {"page": currentPage.value } })
        data.value = [...data.value, ...response.data]
      }
    } catch(e) {
      alert('An error occurred while retrieving data.')
    } finally {
      loading.value = false
    }
  }

  return {
    data, fetchData, fetchAdditionalData, loading, currentPage, totalPages
  }
}
