<template lang="pug">
li.list-group-item.px-0.border-0
  .row.align-items-center
    .col-auto
      i.fas.fa-phone-alt
    .col
      p.text-xs.font-weight-bold.mb-0 New Call:
      h6.text-sm.mb-0
        a(:href='leadUrl') {{ leadName }}
    .col.text-center
      p.text-xs.font-weight-bold.mb-0 Ad Site:
      h6.text-sm.mb-0 {{ adSite }}
    .col.text-center
      div(v-if='callRecordingUrl')
        p.text-xs.font-weight-bold.mb-0 Call recording:
        h6.text-sm.mb-0
          a(:href='callRecordingUrl' target='_blank') Listen

  hr.horizontal.dark.mt-3.mb-1
</template>

<script>
  import { get, startCase } from 'lodash'

  export default {
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      leadName() {
        return get(this, 'item.twilio_call.contact.full_name')
      },
      leadUrl() {
        const lead_id = get(this, 'item.twilio_call.contact.id')

        if (!lead_id) { return null }

        return `/leads/${lead_id}/edit`
      },
      callRecordingUrl() {
        return get(this, 'item.twilio_call.recording_url')
      },
      adSite() {
        return startCase(get(this, 'item.twilio_call.phone.ad_site', 'Unknown'))
      }
    },
    setup(props, { emit }) {
    }
  }
</script>

<style lang="sass" scoped>
</style>
